
import { useLocation, useParams } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import "./VideoKYC.css";

function VideoKYC({ jwt }) {
  const [kycStatus, setKycStatus] = useState("Click to start Video KYC");
  const [step, setStep] = useState(0);
  const [language, setLanguage] = useState("");
  const [data, setData] = useState("");
  const [aadata, setaaData] = useState("");
  const [panImageFile, setPanImageFile] = useState(null);
  const [aadhaarImageFile, setaadhaarImageFile] = useState(null);
  const [aadhaarFrontImageFile, setAadhaarFrontImageFile] = useState(null);
  const [capturedImageSrc, setCapturedImageSrc] = useState(null);
  const webcamRef = useRef(null);
  const [isWebcamStarted, setIsWebcamStarted] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const [borderColor, setBorderColor] = useState("");
  const [hasDocuments, setHasDocuments] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const [maxAttempts, setMaxAttempts] = useState(3);
  const [isRecording, setIsRecording] = useState(false);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [audioPermissionGranted, setAudioPermissionGranted] = useState(false);
  const token2 =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNDIsImVtYWlsIjoidmlkZW9reWNAc29mdGZ1dHVyYS5jb20iLCJwaG9uZV9ubyI6IjEyMzQ1Njc4OTEiLCJ1c2VydHlwZSI6IlVzZXIiLCJhZ2VudF9zdGF0dXMiOiIwIiwiaWF0IjoxNzM1MDI0MTQ0fQ.z1ncX_cXoZl0eZaqxgoU9o8kVbhTJv8M4f1FbDA4v9U";



    useEffect(() => {
      
      const requestAudioPermission = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          if (stream) {
            setAudioPermissionGranted(true);
          }
        } catch (error) {
          console.error('Error accessing audio devices:', error);
          
        }
      };
  
      if (!audioPermissionGranted) {
        requestAudioPermission();
      }
    }, [audioPermissionGranted]);

    
  const videoConstraints = {
    width: 740,
    height: 420,
    facingMode: facingMode,
  };

  const speakText = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = language === "hindi" ? "hi-IN" : "en-US";
    synth.speak(utterance);
  };

  const handleLanguageSelection = (language) => {
    setLanguage(language);


    
    

    if (audioPermissionGranted) {
      if (language === "english") {
        const audio = new Audio("https://live.kycfi.in/uploads/efirst.mp3");
        audio.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      } else {
        const audio = new Audio("https://live.kycfi.in/uploads/first.mp3");
        audio.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      }
    } else {
      alert('Audio playback permission is required. Please grant permission.');
    }

    setStep(1);
  };

  const proceedToVideoKYC = (e) => {
    e.preventDefault();
    if (hasDocuments && acceptTerms) {
      setTimeout(() => {
        setKycStatus("Video started, click PAN for Capture");
        

        if (audioPermissionGranted) {
          if (language === "english") {
            const audio = new Audio("https://live.kycfi.in/uploads/esecond.mp3");
            audio.play().catch(error => {
              console.error('Error playing audio:', error);
            });
          } else {
            const audio = new Audio("https://live.kycfi.in/uploads/second.mp3");
            audio.play().catch(error => {
              console.error('Error playing audio:', error);
            });
          }
        } else {
          alert('Audio playback permission is required. Please grant permission.');
        }

        setIsWebcamStarted(true);

        setStep(3);
      }, 2000);
    }
  };

  const captureImageAsBlob = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImageSrc(imageSrc);
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: "image/jpeg" });
  };

  const handleErrorAndRetry = (retryFunction, stepToSet, message) => {
    setAttempts((prevAttempts) => {
      if (prevAttempts < maxAttempts) {
        setTimeout(() => {
          retryFunction();
        }, 5000);

        return prevAttempts + 1;
      } else {
        stopRecording();

        setTimeout(() => {
          axios
            .post("https://live.kycfi.in/api/update_videokyc_data", {
              max_limit: 1,
              token: jwt,
            })
            .then((response) => {})
            .catch((error) => {
              if (error.response) {
                console.error("Error Response:", error.response.data);
                console.error("Status:", error.response.status);
              } else if (error.request) {
                console.error("No Response Received:", error.request);
              } else {
                console.error("Error Message:", error.message);
              }
            });
          window.location.reload();
        }, 2000);
        setIsWebcamStarted(false);
        setStep(0);

        speakText("oh no you excced the maximum limit");

        return prevAttempts;
      }
    });
  };

  useEffect(() => {
    if (videoBlob) {
      uploadVideo();
      setIsWebcamStarted(false);
    }
  }, [videoBlob]);

  const startRecording = async () => {
    try {
		
		  const permissionStatus = await navigator.permissions.query({ name: "camera" });
    
    if (permissionStatus.state === "denied") {
      alert("Camera permission is denied. Please enable it in your browser settings.");
      return;
    }
		
		
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setIsRecording(true);

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: "video/webm" });

        setVideoBlob(blob);

        if (videoRef.current && videoRef.current.srcObject) {
          videoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
        }
      };

      mediaRecorder.start();
    } catch (error) {
      console.error("Error starting video recording:", error);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const uploadVideo = async () => {
    if (videoBlob) {
      const formData = new FormData();
      formData.append("file", videoBlob, "kyc-video.webm");
      formData.append("token", jwt);

      try {
        const response = await axios.post(
          "https://live.kycfi.in/api/upload_kyc_video",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    } else {
      console.error("No videoBlob available for upload.");
    }
  };

  const capturePanForCapture = async () => {
    setBorderColor("");
    const imgFile = await captureImageAsBlob();
    startRecording();
     
    
    if (imgFile) {
      const formData = new FormData();
      formData.append("file", imgFile);
      formData.append("token", jwt);

      setTimeout(async () => {
        try {
          const response = await axios.post(
            "https://live.kycfi.in/api/ocr/pan_image_upload1",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token2}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          
  

          setData(response.data);
          setBorderColor("green");
          setPanImageFile(imgFile);



          navigator.geolocation.getCurrentPosition(
            async (position) => {
              
              const { latitude, longitude } = position.coords;
              console.log("latitude", latitude);
              console.log("longitude", longitude);
          
            
              sendApiRequest(latitude, longitude);
            },
            (error) => {
       
              console.error("Geolocation Error:", error.message);
          
              sendApiRequest(0, 0); 
            }
          ); 
			
			
			
			    
          function sendApiRequest(latitude, longitude) {
            axios
              .post("https://live.kycfi.in/api/update_videokyc_data", {
                pan: response.data, 
                token: jwt,         
                pan_location: { lat: latitude, lon: longitude },
              })
              .then((response) => {
                console.log("API Response:", response.data);
              })
              .catch((error) => {
                if (error.response) {
                  console.error("Error Response:", error.response.data);
                  console.error("Status:", error.response.status);
                } else if (error.request) {
                  console.error("No Response Received:", error.request);
                } else {
                  console.error("Error Message:", error.message);
                }
              });
          }

          if (audioPermissionGranted) {
            if (language === "english") {
              setKycStatus(
                "PAN Capture successful! Now click Capture Aadhaar front."
              );
              const audio = new Audio("https://live.kycfi.in/uploads/ethird.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            } else {
               
            setKycStatus(
              "पैन कैप्चर सफल! - अब कैप्चर आधार फ्रंट पर क्लिक करें।"
            );
              const audio = new Audio("https://live.kycfi.in/uploads/third.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            }
          } else {
            alert('Audio playback permission is required. Please grant permission.');
          }
          
          

          
          setTimeout(() => {
            setStep(4);
          }, 7000);
          setAttempts(0);
        } catch (error) {
          setBorderColor("red");
          handleErrorAndRetry(
            capturePanForCapture,
            3,
            "PAN Capture failed. Try again."
          );

          if (audioPermissionGranted) {
            if (language === "english") {
              setKycStatus("PAN Capture failed. Try again.");
              const audio = new Audio("https://live.kycfi.in/uploads/efourth.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            } else {
              setKycStatus("पैन कैप्चर विफल रहा. पुनः प्रयास करें।");
              const audio = new Audio("https://live.kycfi.in/uploads/fourth.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            }
          } else {
            alert('Audio playback permission is required. Please grant permission.');
          }
        
         
          setStep(3);
        }
      }, 7000);
    }
  };


  
  const captureAadhaarForCapture = async () => {
    setBorderColor("");
    const imgFile = await captureImageAsBlob();
    if (step === 4) {
      setAadhaarFrontImageFile(imgFile);
      setBorderColor("green");

      if (audioPermissionGranted) {
      if (language === "english") {
        setKycStatus("Aadhaar front captured! Now click Capture Aadhaar back.");
        const audio = new Audio("https://live.kycfi.in/uploads/efifth.mp3");
        audio.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      } else {
        setKycStatus(
          "आधार का अगला हिस्सा कैप्चर! - अब कैप्चर आधार बैक पर क्लिक करें"
        );
        const audio = new Audio("https://live.kycfi.in/uploads/fifth.mp3");
        audio.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      }
    } else {
      alert('Audio playback permission is required. Please grant permission.');
    }


      
      setaadhaarImageFile(imgFile);
      setTimeout(() => {
        setStep(5);
      }, 5000);
    } else if (step === 5) {
      setBorderColor("");
      const backImageFile = await captureImageAsBlob();
      if (aadhaarFrontImageFile && backImageFile) {
        const formData = new FormData();
        const formData1 = new FormData();
        formData.append("file", aadhaarFrontImageFile);
        formData1.append("file", aadhaarFrontImageFile);
        formData.append("file_back", backImageFile);
        formData1.append("token", jwt);

        try {
          const response = await axios.post(
            "https://live.kycfi.in/api/ocr/aadhaar_upload1",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token2}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );


          

          setaaData(response.data);

          navigator.geolocation.getCurrentPosition(
            async (position) => {
              
              const { latitude, longitude } = position.coords;
              console.log("latitude", latitude);
              console.log("longitude", longitude);
          
            
              sendApiRequest(latitude, longitude);
            },
            (error) => {
       
              console.error("Geolocation Error:", error.message);
          
              sendApiRequest(0, 0); 
            }
          );
          
         
          function sendApiRequest(latitude, longitude) {
            axios
              .post("https://live.kycfi.in/api/update_videokyc_data", {
                aadhaar: response.data, 
                token: jwt,         
                aadhaar_location: { lat: latitude, lon: longitude },
              })
              .then((response) => {
                console.log("API Response:", response.data);
              })
              .catch((error) => {
                if (error.response) {
                  console.error("Error Response:", error.response.data);
                  console.error("Status:", error.response.status);
                } else if (error.request) {
                  console.error("No Response Received:", error.request);
                } else {
                  console.error("Error Message:", error.message);
                }
              });
          }


          if (audioPermissionGranted) {
            if (language === "english") {
              setKycStatus(
                "Aadhaar Capture successful! Now perform face verification."
              );
              const audio = new Audio("https://live.kycfi.in/uploads/esixth.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            } else {
              setKycStatus("आधार कैप्चर सफल! अब फेस वेरिफिकेशन करें.");
              const audio = new Audio("https://live.kycfi.in/uploads/sixth.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            }
          } else {
            alert('Audio playback permission is required. Please grant permission.');
          }


          
          setTimeout(() => {
            setStep(6);
          }, 7000);
          setAttempts(0);
        } catch (error) {
          setBorderColor("red");
          handleErrorAndRetry(
            captureAadhaarForCapture,
            4,
            "Aadhaar Capture failed. Try again."
          );

          if (audioPermissionGranted) {
            if (language === "english") {
              setKycStatus("Aadhaar Capture failed. Try again.");
              const audio = new Audio("https://live.kycfi.in/uploads/eseventh.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            } else {
              setKycStatus("आधार कैप्चर विफल रहा. पुनः प्रयास करें।");
              const audio = new Audio("https://live.kycfi.in/uploads/seventh.mp3");
              audio.play().catch(error => {
                console.error('Error playing audio:', error);
              });
            }
          } else {
            alert('Audio playback permission is required. Please grant permission.');
          }

          

          setStep(4);
        }
      }
    }
  };

  const checkFaceLive = async () => {
    setBorderColor("");
    const liveImageFile = await captureImageAsBlob();
    if (liveImageFile) {
      const formData = new FormData();
      formData.append("file", liveImageFile);
      formData.append("token", jwt);

      try {
        const response = await axios.post(
          "https://live.kycfi.in/api/face/face_Liveness_verification",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token2}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );


       
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            
            const { latitude, longitude } = position.coords;
            console.log("latitude", latitude);
            console.log("longitude", longitude);
        
          
            sendApiRequest(latitude, longitude);
          },
          (error) => {
     
            console.error("Geolocation Error:", error.message);
        
            sendApiRequest(0, 0); 
          }
        );
        
       
        function sendApiRequest(latitude, longitude) {
          axios
            .post("https://live.kycfi.in/api/update_videokyc_data", {
              face: response.data, 
              token: jwt,         
              user_location: { lat: latitude, lon: longitude },
            })
            .then((response) => {
              console.log("API Response:", response.data);
            })
            .catch((error) => {
              if (error.response) {
                console.error("Error Response:", error.response.data);
                console.error("Status:", error.response.status);
              } else if (error.request) {
                console.error("No Response Received:", error.request);
              } else {
                console.error("Error Message:", error.message);
              }
            });
        }


        if (audioPermissionGranted) {
          if (language === "english") {
            setKycStatus("KYC completed.");
            const audio = new Audio("https://live.kycfi.in/uploads/eten.mp3");
            audio.play().catch(error => {
              console.error('Error playing audio:', error);
            });
          } else {
            setKycStatus("केवाईसी पूरा हो गया.");
            const audio = new Audio("https://live.kycfi.in/uploads/ten.mp3");
            audio.play().catch(error => {
              console.error('Error playing audio:', error);
            });
          }
        } else {
          alert('Audio playback permission is required. Please grant permission.');
        }

        
        

        setBorderColor("green");
        setAttempts(0);
        setStep(0);
        stopRecording();

        setTimeout(() => {
          axios
            .post("https://live.kycfi.in/api/update_videokyc_data", {
              kyc_status: 1,
              token: jwt,
            })
            .then((response) => {})
            .catch((error) => {
              if (error.response) {
                console.error("Error Response:", error.response.data);
                console.error("Status:", error.response.status);
              } else if (error.request) {
                console.error("No Response Received:", error.request);
              } else {
                console.error("Error Message:", error.message);
              }
            });
          window.location.reload();
        }, 2000);
      } catch (error) {
        console.error("Live Check Error:", error);
        setBorderColor("red");
        handleErrorAndRetry(checkFaceLive, 6, "Live check failed. Try again.");

        if (audioPermissionGranted) {
          if (language === "english") {
            setKycStatus("Live check failed. Try again.");
            const audio = new Audio("https://live.kycfi.in/uploads/enine.mp3");
            audio.play().catch(error => {
              console.error('Error playing audio:', error);
            });
          } else {
            setKycStatus("लाइव जाँच विफल रही. पुनः प्रयास करें।");
            const audio = new Audio("https://live.kycfi.in/uploads/nine.mp3");
            audio.play().catch(error => {
              console.error('Error playing audio:', error);
            });
          }
        } else {
          alert('Audio playback permission is required. Please grant permission.');
        }

       
        setStep(6);
      }
    }
  };

  useEffect(() => {
    if (step === 3) {
        setTimeout(() => {
          capturePanForCapture()
        }, 1500);
      };
    if (step === 4 || step === 5) captureAadhaarForCapture();
    if (step === 6) checkFaceLive();
  }, [step]);

  return (
    <div className="video-kyc-container" >
      {step === 0 && (
        <div className="language-selection  ">
          <h3>Select your preferred language</h3>
          <div className="flex justify-center items-center "> 
          <button
            className="action-button "
            style={{ height: "40px", width: "80px", marginRight: "10px" }}
            onClick={() => handleLanguageSelection("english")}
            disabled={!audioPermissionGranted}
          >
            English
          </button>
          <button
            className="action-button"
            style={{ height: "40px", width: "80px", marginRight: "10px" }}
            onClick={() => handleLanguageSelection("hindi")}
            disabled={!audioPermissionGranted}
          >
            Hindi
          </button>
          </div>
        </div>
      )}

      {step === 1 && (
        <div>
          <h3>Required Documents:</h3>
          <ul>
            {language === "english" ? (
              <>
                <li>PAN Card</li>
                <li>Aadhaar Card (Front and Back)</li>
              </>
            ) : (
              <>
                <li>पैन कार्ड</li>
                <li>आधार कार्ड (आगे और पीछे)</li>
              </>
            )}
          </ul>

          <img
            src="https://miro.medium.com/v2/resize:fit:1200/1*1pRcPszv5S-7ZMDQKKAOlw.png"
            width="400px"
            alt="Required Documents"
          />

          <br />
          <br />
          <form onSubmit={proceedToVideoKYC}>
            <label>
              <input
                type="checkbox"
                checked={hasDocuments}
                onChange={(e) => setHasDocuments(e.target.checked)}
                style={{ cursor: "pointer" }}
              />
              {language === "english" ? (
                <>I have all these documents to start Video KYC</>
              ) : (
                <>
                  मेरे पास वीडियो केवाईसी शुरू करने के लिए ये सभी दस्तावेज़ हैं
                </>
              )}
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                style={{ cursor: "pointer" }}
              />
              {language === "english" ? (
                <>I accept all the terms and conditions</>
              ) : (
                <>मुझे सभी नियम एवं शर्तें स्वीकार हैं</>
              )}
            </label>
            <br />
            <br />
            <button
              type="submit"
              className="action-button"
              disabled={!acceptTerms || !hasDocuments}
            >
              {language === "english" ? (
                <>Proceed to Video KYC</>
              ) : (
                <>वीडियो केवाईसी के लिए आगे बढ़ें</>
              )}
            </button>
          </form>
        </div>
      )}

      {step >= 3 && (
        <div>
          <h3>{kycStatus}</h3>
          {isWebcamStarted && (
            <div className="webcam-container">
              <Webcam
               
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                className={`webcam-frame ${
                  borderColor ? `border-${borderColor}` : ""
                }`}
              />
            </div>
          )}
        </div>
      )}
      <div
        style={{
          marginTop: "20px",
          fontSize: "18px",
          color: "#888",
          textAlign: "center",
        }}
      >
        Powered by KYCFi
      </div>
    </div>
  );
}

export default VideoKYC;
