import React from "react";

const KycLimitExceeded = () => {
  return (
    <div className="flex flex-col items-center p-8 bg-gradient-to-r from-red-400 via-orange-500 to-yellow-600 text-white border-2 border-red-500 rounded-lg shadow-xl max-w-lg mx-auto transform transition-all duration-300 hover:scale-105">
      <div className="text-6xl mb-4">
        <i className="fas fa-times-circle"></i>
      </div>
      <h2 className="text-4xl font-bold mb-2 animate__animated animate__fadeInUp">
        Limit Exceeded
      </h2>
      <p className="text-lg mb-6 text-center opacity-90">
        You have exceeded the maximum number of attempts for video KYC. 
        <span className="block mt-2 font-semibold">Please contact support for further assistance.</span>
      </p>
      <a
        href="mailto:support@example.com"
        className="px-8 py-3 bg-gradient-to-r from-red-500 to-orange-600 text-white rounded-lg shadow-md hover:bg-gradient-to-l from-orange-600 to-red-500 transition duration-300 transform hover:scale-105"
      >
        Contact Support
      </a>
    </div>
  );
};

export default KycLimitExceeded;
