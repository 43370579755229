import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import VideoKYC from './VideoKYC'; 
import KycSuccess from './KycSuccess';
import KycLimitExceeded from './KycLimitExceeded';

const ShowScreen = () => {
  const [screenshow, setScreenshow] = useState(false);
  const [kycstatus, setkycstatus] = useState(false);
  const { jwt } = useParams();


  const screenshowcheck = async () => {
    try {
      const response = await axios.post(
        'https://live.kycfi.in/api/user/videokyc/checktoken',
        { token: jwt },  
        {
          headers: {
            // Authorization: `Bearer ${token1}`,
          },
        }
      );
      
      console.log('Response:', response.data);
      setScreenshow(response.data.result); 
      setkycstatus(response.data.kycstatus);
    } catch (error) {
      console.error('Error checking token:', error);
      setScreenshow(false); 
    }
  };


  useEffect(() => {
    screenshowcheck();
  }, [jwt]);  

  return (
    <div>
      {screenshow ? <VideoKYC jwt={jwt}   /> : <KycSuccess/>}  
    </div>
  );
};

export default ShowScreen;
