// import React from "react";

// const KycSuccess = () => {
//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-pink-400 via-indigo-500 to-yellow-500 text-white p-8">
//       <div className="bg-white text-gray-800 p-8 rounded-lg shadow-xl max-w-lg mx-auto">
//         <div className="text-6xl mb-4 text-center text-pink-500">
//           <i className="fas fa-thumbs-up"></i>
//         </div>
//         <h2 className="text-5xl font-extrabold mb-6 text-center text-gradient bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
//           Thank You for Your Attention!
//         </h2>
//         <p className="text-lg text-center mb-6 text-blue-700">
//           We appreciate your time and effort. Rest assured, we're on it and will get back to you shortly.
//         </p>
//         <p className="text-lg text-center mb-6 opacity-80 text-orange-600">
//           If you need any further assistance, feel free to reach out to us anytime.
//         </p>
//         <button className="px-8 py-3 bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 hover:bg-gradient-to-l text-white rounded-lg shadow-md transform transition duration-300 hover:scale-105">
//           Go Back to Dashboard
//         </button>
//       </div>
//     </div>
//   );
// };

// export default KycSuccess;




import React from 'react'

const KycSuccess = () => {
  return (
    <div></div>
  )
}

export default KycSuccess